import RawEndpoint from '@/app/base/endpoints/RawEndpoint';
import { JsonRequestPayload } from '@/app/base/endpoints/types';

export interface ValidatedSiretResponse {
    error?: {
        status: number;
        message: string;
        source?: 'vies';
    };
    data?: ValidatedSiretResponseData;
}

export interface ValidatedSiretResponseData {
    company: string;
    isOpen: boolean;
    companyType: string;
    address: {
        raw: string;
        display: string;
        street: string;
        city: string;
        postalCode: string;
    };
}

export default class ValidateSiretEndpoint extends RawEndpoint {
    path: string = 'common/siret';

    async validate(siret: string): Promise<ValidatedSiretResponseAttributes> {
        const payload: JsonRequestPayload = {
            method: 'POST',
            path: '-actions/validate',
            data: {
                data: {
                    type: 'siret-validation',
                    attributes: {
                        siret,
                    },
                },
            },
        };

        const response = await this.requestRaw(payload);
        const { data, errors } = await response.json();
        console.log('ValidateSiretEndpoint', data, errors);

        if (errors && errors.length > 0) {
            return {
                error: {
                    status: errors[0].status,
                    message: errors[0].detail,
                    source: errors[0].source,
                },
            };
        }

        return {
            data: data.attributes,
        };
    }
}
