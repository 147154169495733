export default (place: google.maps.places.PlaceResult): Address => {
    console.log(place);
    const address: Address = {
        city: null,
        country: null,
        display: place.formatted_address,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        postalCode: null,
        province: null,
        streetName: null,
        streetNumber: null,
        streetBoxNumber: null,
    };

    for (let i = 0; i < place.address_components.length; i++) {
        const component = place.address_components[i];
        const types = component.types;
        if (types.includes('street_number')) {
            address.streetNumber = component.long_name;
        }

        if (types.includes('route')) {
            address.streetName = component.long_name;
        }

        if (types.includes('locality')) {
            address.city = component.long_name;
        }

        if (types.includes('administrative_area_level_1')) {
            address.province = component.long_name;
        }

        if (types.includes('country')) {
            address.country = component.long_name;
        }

        if (types.includes('postal_code')) {
            address.postalCode = component.long_name;
        }
    }

    return address;
};
