<script setup lang="ts">
interface _VTI_TYPE_WritableComputedRef {
    value: _VTI_TYPE_T;
    /**
     * Type differentiator only.
     * We need this to be in public d.ts but don't want it to show up in IDE
     * autocomplete, so we use a private Symbol instead.
     */
    [RefSymbol]: true;
    readonly effect: _VTI_TYPE_ReactiveEffect;
}
interface FormInputProps {
    label?: string;
    inlineLabel?: boolean;
    placeholder?: string;
    elementClassName?: string;
    containerClassName?: string;
    className?: string;
    inputId?: string;
    inputType?: string;
    step?: number;
    disabled?: boolean;
    withoutLabel?: boolean;
    tooltip?: string;
    autofocus?: boolean;
    required?: boolean;
    translated?: boolean;
    withVariables?: boolean;
    form: _VTI_TYPE_Form;
    model?: _VTI_TYPE_WritableComputedRef;
    inputName: string;
    withNullOption?: boolean;
}
    import ValidateSiretEndpoint, { ValidatedSiretResponse } from '@/app/common/endpoints/ValidateSiretEndpoint'
    import { useDebounceFn } from '@vueuse/core'
    import { FormInputData } from '@/composables/useFormInput'
    const emit = defineEmits(['validSiret']);
    const props = defineProps({
  label: null,
  inlineLabel: { type: Boolean },
  placeholder: null,
  elementClassName: null,
  containerClassName: null,
  className: null,
  inputId: null,
  inputType: null,
  step: null,
  disabled: { type: Boolean },
  withoutLabel: { type: Boolean },
  tooltip: null,
  autofocus: { type: Boolean },
  required: { type: Boolean },
  translated: { type: Boolean },
  withVariables: { type: Boolean },
  form: null,
  model: null,
  inputName: null,
  withNullOption: { type: Boolean }
});
    const inputData: FormInputData = useFormInput(props);
    const endpoint = new ValidateSiretEndpoint();
    const response = ref<ValidatedSiretResponse | null>(null);
    const abortController = ref<AbortController | null>(null);
    const validatingSiret = ref(false);
    const onInputChange = async () => {
        if (!inputData.model.value) {
            validatingSiret.value = false;
            response.value = null;
            return;
        }
        validatingSiret.value = true;
        if (abortController.value) {
            console.log('Aborting previous search...');
            abortController.value.abort();
        }
        await validateSiret();
    };
    const validateSiret = useDebounceFn(async () => {
        if (!inputData.model.value) {
            return;
        }
        abortController.value = new AbortController();
        try {
            response.value = await endpoint.withAbortController(abortController.value).validate(inputData.model.value);
            if (response.value && response.value.data) {
                emit('validSiret', response.value);
            }
        } catch (e) {
            if (e.name === 'AbortError') {
                console.log('Aborted');
            } else {
                console.error(e);
            }
        }
        validatingSiret.value = false;
    }, 200);
    const isValid = computed(() => {
        return !inputData.model.value ? true : response.value?.data;
    });
    defineExpose({
        isValid,
    });
    onMounted(async () => {
        validateSiret();
    });
</script>

<template>
    <FormInput
        v-bind="props"
        @input="
            validatingSiret = true;
            onInputChange();
        "
    >
        <template
            v-slot:after-input
            v-if="inputData.model.value"
        >
            <template v-if="validatingSiret">
                <span class="text-[11px] text-gray-400">
                    <i class="fa-duotone fa-loader fa-spin"></i>
                    {{ $t('misc.siret_input.validating') }}
                </span>
            </template>
            <template v-if="!validatingSiret && response">
                <span
                    class="text-[11px] text-red-600"
                    v-if="response.error"
                >
                    <i class="fa-regular fa-xmark" />
                    {{ response.error.message }}
                </span>
                <span
                    class="text-[11px]"
                    :class="response.data.isOpen ? 'text-green-600' : 'text-orange-600'"
                    v-if="response.data"
                >
                    <i class="fa-regular fa-check" />
                    {{ response.data.isOpen ? $t('misc.siret_input.valid') : $t('misc.siret_input.valid_but_closed') }}
                </span>
            </template>
        </template>
    </FormInput>
</template>
