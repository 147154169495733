<script setup lang="ts">
    import Str from '@supercharge/strings';
    import { FormInputData } from '~~/composables/useFormInput';

    const emit = defineEmits(['switch']);
    const props = defineProps<{
        inputData: FormInputData;
    }>();

    const switchToAutocomplete = () => {
        props.inputData.model.value.display = Str(props.inputData.model.value.streetName)
            .append(' ')
            .append(props.inputData.model.value.streetNumber)
            .append(' ')
            .append(props.inputData.model.value.streetBoxNumber)
            .trim()
            .append(', ')
            .append(props.inputData.model.value.postalCode)
            .append(' ')
            .append(props.inputData.model.value.city)
            .append(', ')
            .append(props.inputData.model.value.country);

        emit('switch');
    };
</script>

<template>
    <div class="flex flex-col mb-6 space-y-3">
        <div class="flex items-center space-x-4">
            <div class="flex-1">
                <FormInput
                    element-class-name="relative"
                    :form="inputData.form"
                    :input-name="`${inputData.inputName}.streetName`"
                >
                    <template v-slot:after-input>
                        <div class="absolute top-[20px] bottom-0 right-0 flex items-center">
                            <span
                                :data-tooltip="$t('form.misc.use_address_autocomplete')"
                                class="px-3 py-1 text-gray-400 transition-all cursor-pointer hover:text-black"
                                @click.prevent="switchToAutocomplete()"
                            >
                                <i class="text-sm fa-regular fa-wrench" />
                            </span>
                        </div>
                    </template>
                </FormInput>
            </div>
            <div class="w-[80px]">
                <FormInput
                    element-class-name=" "
                    :form="inputData.form"
                    :input-name="`${inputData.inputName}.streetNumber`"
                />
            </div>
            <div class="w-[80px]">
                <FormInput
                    element-class-name=" "
                    :form="inputData.form"
                    :input-name="`${inputData.inputName}.streetBoxNumber`"
                />
            </div>
        </div>
        <div class="flex items-center space-x-4">
            <div class="w-[120px]">
                <FormInput
                    element-class-name=" "
                    :form="inputData.form"
                    :input-name="`${inputData.inputName}.postalCode`"
                />
            </div>
            <div class="flex-1">
                <FormInput
                    element-class-name=" "
                    :form="inputData.form"
                    :input-name="`${inputData.inputName}.city`"
                />
            </div>
            <div class="w-[200px]">
                <FormInput
                    element-class-name=" "
                    :form="inputData.form"
                    :input-name="`${inputData.inputName}.country`"
                />
            </div>
        </div>
    </div>
</template>
