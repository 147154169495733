<script setup lang="ts">
interface _VTI_TYPE_WritableComputedRef {
    value: _VTI_TYPE_T;
    /**
     * Type differentiator only.
     * We need this to be in public d.ts but don't want it to show up in IDE
     * autocomplete, so we use a private Symbol instead.
     */
    [RefSymbol]: true;
    readonly effect: _VTI_TYPE_ReactiveEffect;
}
interface FormInputProps {
    label?: string;
    inlineLabel?: boolean;
    placeholder?: string;
    elementClassName?: string;
    containerClassName?: string;
    className?: string;
    inputId?: string;
    inputType?: string;
    step?: number;
    disabled?: boolean;
    withoutLabel?: boolean;
    tooltip?: string;
    autofocus?: boolean;
    required?: boolean;
    translated?: boolean;
    withVariables?: boolean;
    form: _VTI_TYPE_Form;
    model?: _VTI_TYPE_WritableComputedRef;
    inputName: string;
    withNullOption?: boolean;
}
    import { FormInputData } from '~~/composables/useFormInput'
    const autocomplete = ref(null);
    const props = defineProps({
  label: null,
  inlineLabel: { type: Boolean },
  placeholder: null,
  elementClassName: null,
  containerClassName: null,
  className: null,
  inputId: null,
  inputType: null,
  step: null,
  disabled: { type: Boolean },
  withoutLabel: { type: Boolean },
  tooltip: null,
  autofocus: { type: Boolean },
  required: { type: Boolean },
  translated: { type: Boolean },
  withVariables: { type: Boolean },
  form: null,
  model: null,
  inputName: null,
  withNullOption: { type: Boolean }
});
    const inputData: FormInputData = useFormInput(props);
    const streetBoxNumber = ref('');
    const inputManually = ref(false);
    const displayStreetBoxNumber = ref(false);
    const toggleStreetBoxNumber = () => {
        displayStreetBoxNumber.value = !displayStreetBoxNumber.value;
        streetBoxNumber.value = '';
    };
    const setInputManually = async (value: boolean) => {
        inputManually.value = value;
        if (inputManually.value == false) {
            await nextTick();
            onAutocompleteDisplay();
            return;
        }
        if (typeof inputData.model.value === 'string') {
            inputData.model.value = {
                manual: true,
            };
        }
    };
    const setPlace = (place: google.maps.places.PlaceResult) => {
        inputData.form.set(inputData.inputName, useGooglePlaceToAddress(place));
    };
    const getInput = () => {
        return document.getElementById(inputData.inputId.value);
    };
    // Watch the inputData.model value and update the input value manually
    // This is needed because the GMapAutocomplete component doesn't support v-model
    watch(inputData.model, (value) => {
        if (typeof value === 'string') {
            getInput().value = value;
            getInput().focus();
            return;
        }
        getInput().value = value && value.display ? value.display : '';
    });
    watch(streetBoxNumber, (value: string) => {
        if (inputData.model.value) {
            inputData.model.value.streetBoxNumber = value;
        }
    });
    const onAutocompleteDisplay = () => {
        console.log('onAutocompleteDisplay', inputData.model.value);
        if (inputData.model.value && inputData.model.value.streetBoxNumber) {
            streetBoxNumber.value = inputData.model.value.streetBoxNumber;
            displayStreetBoxNumber.value = true;
        }
        if (getInput()) {
            getInput().value = inputData.model?.value?.display ?? '';
            getInput().addEventListener('blur', () => {
                if (getInput().value !== inputData.model?.value?.display) {
                    inputData.form.set(inputData.inputName, '');
                }
            });
        }
    };
    onMounted(() => {
        if (inputData.model.value && inputData.model.value.manual) {
            inputManually.value = true;
        } else {
            onAutocompleteDisplay();
        }
    });
</script>

<template>
    <div class="w-full">
        <FormAddressManual
            v-if="inputManually"
            :inputData="inputData"
            @switch="setInputManually(false)"
        />
        <FormTwoFields
            :field2-class="displayStreetBoxNumber ? 'w-[80px]' : 'hidden'"
            v-else
        >
            <template #field-1>
                <FormElement :input-data="inputData">
                    <div class="relative">
                        <GMapAutocomplete
                            :id="inputData.inputId.value"
                            ref="autocomplete"
                            :placeholder="inputData.placeholder.value"
                            :class="inputData.className.value"
                            :options="{
                                types: ['address'],
                                componentRestrictions: {
                                    country: ['be', 'fr', 'nl', 'lu', 'de'],
                                },
                            }"
                            @place_changed="setPlace"
                        />
                        <div class="absolute top-0 bottom-0 right-0 flex items-center">
                            <span
                                :data-tooltip="$t('form.misc.write_address_manually')"
                                class="px-3 py-1 text-gray-400 transition-all cursor-pointer hover:text-black"
                                @click.prevent="setInputManually(true)"
                            >
                                <i class="text-sm fa-regular fa-wrench" />
                            </span>
                        </div>
                    </div>
                    <p
                        class="block text-xs mt-[6px] text-gray-400 transition-all cursor-pointer hover:underline hover:text-primary"
                        @click.prevent="toggleStreetBoxNumber()"
                    >
                        {{ displayStreetBoxNumber ? $t('form.misc.hide_street_box_number') : $t('form.misc.display_street_box_number') }}
                    </p>
                </FormElement>
            </template>
            <template
                #field-2
                v-if="displayStreetBoxNumber"
            >
                <div class="mt-[24px]">
                    <input
                        :class="$theme('form.input')"
                        v-model="streetBoxNumber"
                        :placeholder="$t('form.labels.streetBoxNumber')"
                    />
                </div>
            </template>
        </FormTwoFields>
    </div>
</template>
